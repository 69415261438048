const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
  };
  
  const inductionCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case "PAGINATED_INDUCTION_CATEGORY":
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "UPSERT_DATA":
        return { ...state };  
      default:
        return { ...state };
    }
  };
  export default inductionCategoryReducer;
  