const initialState = {
  data: [],
  total: 1,
  params: {},
  inductionCategory: [],
  corporateVideos: []
};

const inductionMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PAGINATED_INDUCTION":
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case "CREATE_INDUCTION":
      return { ...state };
    case "UPDATE_INDUCTION":
      return { ...state };
    case "GET_ACTIVE_INDUCTION_CATEGORY":
      return {
        ...state,
        inductionCategory: action.data
      }
    case "GET_CORPORATE_VIDEO":
      return {
        ...state,
        corporateVideos: action.data
      }
    case "UPSERT_CORPORATE_VIDEO":
      return { ...state };
    default:
      return { ...state };
  }
};
export default inductionMasterReducer;
