import { combineReducers } from "redux";
import AuthUserReducer from "./AuthUserReducer";
import ContactReducer from "./ContactReducer";
import AppSettingsReducer from "./AppSettingsReducer";
import UserSettingsReducer from "./UserSettingsReducer";
import MenuListReducer from "./MenuListReducer";
import AuthReducer from "./auth";
import FormInfoReducer from "./FormInfoReducer";
import UserMasterReducer from "./master/UserMasterReducer";
import PromocodeReducer from "./master/PromocodeMasterReducer";
import SettingMasterReducer from "./master/SettingMasterReducer";
import NotificationMasterReducer from "./master/NotificationMasterReducer";
import CategoryMasterReducer from "./master/CategoryMasterReducer";
import CustomerReducer from "./CustomerReducer/CustomerReducer";
import ProductMasterReducer from "./master/ProductMasterReducer";
import OrdersReducer from "./reports/OrdersReducer/OrdersReducer";
import SellsReducer from "./reports/SellsReportReducer/SellsReducer";
import SubCategoryMasterReducer from "./master/SubCategoryReducer";
import BannerMasterReducer from "./master/BannerMasterReducer";
import PopupMasterReduces from "./master/PopupMasterReduces";

import PurchaseMasterReducer from "./master/PurchaseMasterReducer";
import UnitMasterReducer from "./master/UnitMasterReducer";
import SecondSubCategoryReducer from "./master/SecondSubCategoryReducer";
import SupplierMasterReducer from "./master/SupplierMasterReducer";
import PurchaseReportReducer from "./master/PurchaseReportReducer";
import PurchaseHistoryReducer from "./master/PurchaseHistoryReducer";
import AbundantCartReducer from "./reports/AbundantCartReducer";
import TrendingProductReducer from "./reports/TrendingProductReducer";
import DashboardReducer from "./DashboardReducer";
import EmployeeMasterReducer from "./master/EmployeeMasterReducer";
import DepartmentMasterReducer from "./master/DepartmentMasterReducer";
import VerticalMasterReducer from "./master/VerticalMasterReducer";
import AnnouncementMasterReducer from "./master/AnnouncementMasterReducer";
import ContactCardMasterReducer from "./master/ContactCardMasterReducer";
import TicketCategoryReducer from "./master/TicketCategoryMasterReducer";
import downloadReducer from "./master/DownloadMasterReducer";
import employeeReducer from "./master/EmployeeMasterReducer";
import downloadSectionReducer from "./master/DownloadSectionMasterReducer";
import TopicNameReducer from "./master/TopicNameMasterReducer";
import TopicMediaMasterReducer from "./master/TopicMediaMasterReducer";
import FaqReducer from "./master/FaqMasterReducer";
import TicketsReducer from "./reports/TicketReportReducer/TicketsReducers";
import TownhallMasterReducer from "./master/TownhallMasterReducer";
import QuickLinksMasterReducer from "./master/QuickLinksMasterReducer";
import inductionMasterReducer from "./master/InductionMasterReducer";
import inductionCategoryReducer from './master/InductionCategoryMasterReducer'


const reducers = combineReducers({
  authUser: AuthUserReducer,
  settings: AppSettingsReducer,
  ContactReducer: ContactReducer,
  UserSettingsReducer: UserSettingsReducer,
  menuListReducer: MenuListReducer,
  authReducer: AuthReducer,
  formInfoReducer: FormInfoReducer,
  userMasterReducer: UserMasterReducer,
  promocodeReducer: PromocodeReducer,
  settingMasterReducer: SettingMasterReducer,
  notificationMasterReducer: NotificationMasterReducer,
  categoryMasterReducer: CategoryMasterReducer,
  productMasterReducer: ProductMasterReducer,
  customerReducer: CustomerReducer,
  subcategoryMasterReducer: SubCategoryMasterReducer,
  ordersReducer: OrdersReducer,
  sellsReducer: SellsReducer,
  bannerMasterReducer: BannerMasterReducer,
  popupMasterReduces: PopupMasterReduces,
  purchaseMasterReducer: PurchaseMasterReducer,
  unitMasterReducer: UnitMasterReducer,
  secondSubCategoryReducer: SecondSubCategoryReducer,
  supplierMasterReducer: SupplierMasterReducer,
  purchaseReportReducer: PurchaseReportReducer,
  purchaseHistoryReducer: PurchaseHistoryReducer,
  abundantCartReducer: AbundantCartReducer,
  trendingProductReducer: TrendingProductReducer,
  dashboardReducer: DashboardReducer,
  employeeMasterReducer: EmployeeMasterReducer,
  departmentMasterReducer: DepartmentMasterReducer,
  verticalMasterReducer: VerticalMasterReducer,
  announcementMasterReducer: AnnouncementMasterReducer,
  contactCardMasterReducer: ContactCardMasterReducer,
  ticketCategoryReducer: TicketCategoryReducer,
  downloadReducer: downloadReducer,
  employeeMasterReducer: employeeReducer,
  downloadSectionReducer: downloadSectionReducer,
  topicNameReducer: TopicNameReducer,
  topicMediaMasterReducer: TopicMediaMasterReducer,
  faqReducer: FaqReducer,
  ticketsReducer: TicketsReducer,
  townhallMasterReducer: TownhallMasterReducer,
  quickLinksMasterReducer:QuickLinksMasterReducer,
  inductionMasterReducer : inductionMasterReducer,
  inductionCategoryReducer : inductionCategoryReducer
});

export default reducers;
